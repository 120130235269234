import bg from "../assets/misc/footer.svg";
import { AnimatePresence } from "framer-motion";
import ContactModal from "../components/modals/ContactModal";
import { useState } from "react";
const Footer = () => {
  const open = () => setModalOpen(true);
  const close = () => setModalOpen(false);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <section
        className="flex items-center -mb-[40px] h-[766px]"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="white container mx-auto relative">
          <div className="flex flex-col gap-y-12 text-center items-center ">
            <h1>Have An Inquiry?</h1>
            <p className="leading-[1.7]">
              If you have a general inquiry and would like to speak to our
              expert <br />
              team, you can contact us via email at:{" "}
              <span className="text-main font-semibold underline">
                contact@admiracle.co
              </span>
            </p>

            <button onClick={() => (modalOpen ? close() : open())}>
              <h3>GET STARTED TODAY</h3>
              <p>Unlock Your Marketing Potential</p>
            </button>
            <div>
              <h4 className="text-base font-extrabold">
                Swakopmund, Namibia
              </h4>
              <h4 className="text-base mt-3">
                All Rights Reserved | All Wrongs Reversed
              </h4>
            </div>
            <h4 className="text-base">
              Terms & Conditions | Privacy Policy | Disclaimer
            </h4>
          </div>
        </div>
      </section>{" "}
      <AnimatePresence initial={false} mode="wait">
        {modalOpen && (
          <ContactModal modalOpen={modalOpen} handleClose={close} />
        )}
      </AnimatePresence>
    </>
  );
};

export default Footer;
