import divimg from "../assets/misc/divider.svg";
import taglineImg from "../assets/artwork/tagline.svg";
import { AnimatePresence } from "framer-motion";
import ContactModal from "../components/modals/ContactModal";
import { useState } from "react";
const Clients = () => {
  const open = () => setModalOpen(true);
  const close = () => setModalOpen(false);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <section
        className="py-10 md:py-28 pt-20"
        style={{
          backgroundImage: `url(${divimg})`,
          backgroundPosition: "top",
          backgroundSize: "fit",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container mx-auto relative text-center gap-y-10 md:gap-y-28 grid grid-cols-2 gap-10 items-center">
          <div className="">
            <img src={taglineImg} className="" alt="" />
          </div>
          <div className="flex gap-y-5 flex-col">
            <h2 className="text">Paving your path to market dominance.</h2>
            <p className="">
              Unleash the full potential of your brand with our strategic design
              and innovative marketing solutions. Connect, engage, and rise
              above the competition.
            </p>
            <button onClick={() => (modalOpen ? close() : open())}>
              <h3>GET STARTED TODAY</h3>
              <p>Unlock Your Marketing Potential</p>
            </button>
          </div>
        </div>
      </section>{" "}
      <AnimatePresence initial={false} mode="wait">
        {modalOpen && (
          <ContactModal modalOpen={modalOpen} handleClose={close} />
        )}
      </AnimatePresence>
    </>
  );
};

export default Clients;
