import divimg from "../assets/misc/divider.svg";
import botdiv from "../assets/misc/divider-bottom.svg";
import { TbChevronUp } from "react-icons/tb";

const Faq = () => {
  return (
    <section
      className="py-10 md:py-28 relative"
      style={{
        backgroundImage: `url(${divimg})`,
        backgroundPosition: "top",
        backgroundSize: "fit",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src={botdiv} className="absolute bottom-5" alt="" />
      <div className="container mx-auto relative text-center flex flex-col gap-y-28">
        <div className="grid grid-cols-1 gap-10 md:text-left">
          <div>
            <h2 className="!text-center">
              Frequently Asked <span className="text-main">Questions</span>
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">
                  What social media platforms do you specialize in for marketing
                  and advertising?
                </h3>
              </div>
              <p className="text-base md:text-lg leading-snug md:leading-normal mt-2">
                We specialize in Facebook, Instagram, Twitter, LinkedIn,
                Pinterest, and TikTok.
              </p>
            </div>
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">
                  How do you gauge social media marketing effectiveness and ROI
                  for clients?
                </h3>
              </div>
              <p className="text-base md:text-lg leading-snug md:leading-normal mt-2">
                We measure success using engagement rates, conversion rates,
                website traffic, and sales growth, providing monthly reports.
              </p>
            </div>
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">
                  What methods do you employ to craft compelling content on
                  social platforms?
                </h3>
              </div>
              <p className="text-base md:text-lg leading-snug md:leading-normal mt-2">
                Our strategies include interactive posts, user-generated content
                campaigns, and timely participation in trends, all aligned with
                your brand voice and objectives.
              </p>
            </div>
            <div className="card">
              <div className="flex items-center justify-between">
                <h3 className="text-lg md:text-2xl">
                  How tailored are your social media marketing approaches to
                  different industries and businesses?
                </h3>
              </div>
              <p className="text-base md:text-lg leading-snug md:leading-normal mt-2">
                We offer fully customized strategies based on an in-depth
                understanding of your business, industry, and target audience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
