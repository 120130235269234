import Backdrop from "./Backdrop";
import caseIMG from "../../assets/artwork/contact.svg";
import star from "../../assets/misc/star.svg";
import star2 from "../../assets/misc/star2.svg";
import bg1 from "../../assets/misc/bg1.svg";
import bg2 from "../../assets/misc/bg2.svg";
import flareimg from "../../assets/misc/flares.png";
import { FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";
import { server } from "../../config";
import { useState } from "react";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const ContactModal = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.email === "" ||
      formData.message === ""
    ) {
      return alert("All fields are required");
    }

    fetch(`${server}/contactMessages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({
          name: "",
          phone: "",
          email: "",
          message: "",
        });

        return alert("Form Submitted Successfully");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="modal"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <img
          className="absolute transform rotate-180 right-0 bottom-10"
          src={bg1}
          alt=""
        />
        <img
          className="absolute transform rotate-180 right-0 top-10"
          src={bg2}
          alt=""
        />
        <FaTimes
          className="white absolute top-5 right-5 lg:right-10 lg:top-10 text-lg cursor-pointer z-30"
          onClick={handleClose}
        />
        <div className="flex">
          <div className="relative">
            <img
              className="absolute w-20 bottom-[50px] right-[100px] transform rotate-[-212deg]"
              src={star}
              alt=""
            />
            <img
              className="absolute bottom-[230px] left-[260px] transform rotate-[32deg]"
              src={star}
              alt=""
            />
            <img className="absolute left-16 -top-[30px]" src={star} alt="" />
            <img
              className="absolute left-6 w-20 transform rotate-45 top-[144px]"
              src={star}
              alt=""
            />
            <div className="flex items-center justify-between mb-5">
              <h3 className="font-bold text-3xl white z-20">
                Let’s Revolutionize Your Company
              </h3>
            </div>
            <div className="flex flex-col white gap-4">
              <input
                type="text"
                placeholder="Full Name"
                value={formData.name}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    name: event.target.value,
                  });
                }}
              />

              <div className="flex sm:flex-row flex-col gap-4">
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      phone: event.target.value,
                    });
                  }}
                />

                <input
                  type="text"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      email: event.target.value,
                    });
                  }}
                />
              </div>

              <textarea
                name=""
                placeholder="Message..."
                id=""
                cols="30"
                rows="7"
                value={formData.message}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    message: event.target.value,
                  });
                }}
              ></textarea>
              <button
                onClick={handleSubmit}
                className="w-full !rounded-2xl z-20"
              >
                <h3 className="text-xl font-semibold">Let's Get Started!</h3>
              </button>
            </div>
          </div>
          <div className="hidden lg:block  w-[240px]">
            <img
              src={caseIMG}
              className="absolute w-[340px] -right-6 -top-5"
              alt=""
            />
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ContactModal;
