import Backdrop from "./Backdrop";
import caseIMG from "../../assets/artwork/case.svg";
import star from "../../assets/misc/star.svg";
import star2 from "../../assets/misc/star2.svg";
import bg1 from "../../assets/misc/bg1.svg";
import bg2 from "../../assets/misc/bg2.svg";
import flareimg from "../../assets/misc/flares.png";
import { FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";
import { useState } from "react";
import { server } from "../../config";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const CaseStudyModal = ({ handleClose }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email === "") {
      return alert("Email is required");
    }

    fetch(`${server}/subscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          return alert(data.message);
        }

        setEmail("");
        return alert("Email sent successfully");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="modal w-[840px]"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <img
          className="absolute transform rotate-180 right-0 bottom-10"
          src={bg1}
          alt=""
        />
        <img
          className="absolute transform rotate-180 right-0 top-10"
          src={bg2}
          alt=""
        />
        <FaTimes
          className="white absolute top-5 right-5 lg:right-10 lg:top-10 text-lg cursor-pointer z-30"
          onClick={handleClose}
        />
        <div className="flex gap-20">
          <div className="relative">
            <img
              className="absolute w-20 -z-10 top-[50px] right-[100px] transform rotate-[-212deg]"
              src={star}
              alt=""
            />
            <div className="flex items-center justify-between mb-5">
              <h3 className="font-bold !text-center md:text-left text-3xl text-main z-20">
                Our Approach in Action
              </h3>
            </div>
            <div className="flex flex-col white gap-4 z-20 w-[100%]">
              <div className="flex flex-col gap-4">
                <p>
                  How we reliably produce highly impactful returns on investment
                  for our customers.
                </p>
                <p>
                  Strategies to attain an all-encompassing presence akin to a
                  deity and surpass your rivals.
                </p>
                <p>
                  The importance of addressing deficiencies in your sales
                  process before directing traffic to your business.
                </p>
              </div>
              <input
                type="text"
                placeholder="Enter Your Email Address..."
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <button
                onClick={handleSubmit}
                className="w-full rounded-2xl z-40"
              >
                <h3>CLAIM THE CASE STUDY!</h3>
                <p>Instant Access To Our FREE case study!</p>
              </button>
            </div>
          </div>
          <div className="hidden lg:block z-50 w-[450px] ">
            <img
              src={caseIMG}
              className="absolute w-[370px] -right-6 -top-6"
              alt=""
            />
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default CaseStudyModal;
