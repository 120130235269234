import Biography from "../sections/Biography";
import Clients from "../sections/Clients";
import Hero from "../sections/Hero";
import Solutions from "../sections/Solutions";
import CaseStudy from "../sections/CaseStudy";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";

const Landing = () => {
  return (
    <div>
      <Hero />
      <Clients />
      <Biography />
      <Solutions />
      <CaseStudy />
      <Faq />
    </div>
  );
};

export default Landing;
