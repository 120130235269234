import { React, useState } from "react";
import { Route, Routes } from "react-router-dom";

// -- pages
import Landing from "./pages/Landing";

// -- components
import Navbar from "./components/Navbar";
import Footer from "./sections/Footer";

const App = () => {
  return (
    <>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
