import React, { useState } from "react";
import CaseStudyModal from "../components/modals/CaseStudyModal";
import Bg from "../assets/misc/casestudy.svg";
import { AnimatePresence } from "framer-motion";
import Caseimg from "../assets/artwork/casestudy.svg";
import CalendlyWidget from "./CalendlyWidget";

const CaseStudy = () => {
  const open = () => setModalOpen(true);
  const close = () => setModalOpen(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <section
        className="h-[1056px] flex items-center"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="white container mx-auto relative z-20">
          <h3 className="!text-center">
            Claim Your Free Discovery Call Now
          </h3>
          <p className="leading-[1.7] !text-center mt-7">
            Explore tailored solutions and insights during your complimentary
            Discovery Call. Seize the chance to elevate your goals and
            aspirations. Schedule today and transform your path to success!
          </p>
          <CalendlyWidget />
        </div>
      </section>
      <AnimatePresence initial={false} mode="wait">
        {modalOpen && (
          <CaseStudyModal modalOpen={modalOpen} handleClose={close} />
        )}
      </AnimatePresence>
    </>
  );
};

export default CaseStudy;
